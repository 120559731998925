import './util/polyfills'
import 'lazysizes'
import 'lazysizes/plugins/bgset/ls.bgset.min'
import 'lazysizes/plugins/parent-fit/ls.parent-fit.min'
import 'lazysizes/plugins/attrchange/ls.attrchange.min'
// import 'lazysizes/plugins/blur-up/ls.blur-up.min'
import autosize from 'autosize'

import Vue from "vue";
import vmodal from 'vue-js-modal'

import * as VueGoogleMaps from 'vue2-google-maps'

import BadgerAccordion from 'badger-accordion'
import SwiperSlider from './components/SwiperSlider'
import Contact from './components/Contact'
import NewsList from './components/NewsList'
import Team from './components/Team'
import TeamMember from './components/TeamMember'
import BurgerButton from './components/BurgerButton'
import Competence from './components/Competence'
import CookieConsent from './components/CookieConsent'
import AnchorLink from './components/AnchorLink'
import PermissionsHelper from '@/util/permissions'
import NewsletterOptIn from './components/NewsletterOptIn'
import eventBus from '@/util/event-bus'
import { EVENT } from '@/util/enums'

import './style/app.css';
import './util/svg-imports';
import 'badger-accordion/dist/badger-accordion.css'

Vue.config.productionTip = false;

Vue.component('Team', Team)
Vue.component('NewsList', NewsList)
Vue.component('TeamMember', TeamMember)
Vue.component('SwiperSlider', SwiperSlider)
Vue.component('Contact', Contact)
Vue.component('BurgerButton', BurgerButton)
Vue.component('CookieConsent', CookieConsent)
Vue.component('AnchorLink', AnchorLink)
Vue.component('NewsletterOptIn', NewsletterOptIn)

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAPS_KEY,
    v: 'weekly'
  }
})

Vue.use(vmodal)

new Vue({
  el: "#hml-root",
  delimiters: ["${", "}"]
});

setTimeout(() => {
  document.querySelector('html').classList.add('loaded')

  const mapsOptOutLink = document.getElementById('opt-out-maps')

  if(mapsOptOutLink) {
    mapsOptOutLink.addEventListener('click', () => {
      PermissionsHelper.mapsPermission = 0
    })
  }

  const accordions = document.querySelectorAll('.js-badger-accordion')
  for (let i = 0; i < accordions.length; i++) {
    const accordion = accordions[i]
    new BadgerAccordion(accordion)

  }

  const competenceAccordion = document.querySelector('.js-competence-accordion')
  if(competenceAccordion) {
    new Competence(competenceAccordion)
  }
}, 300)

window.addEventListener('storage', () => {
  eventBus.$emit(EVENT.PERMISSIONS_CHANGED)
})

autosize(document.querySelectorAll('textarea'))

