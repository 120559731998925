<template>
  <div class="swiper-container">
    <slot name="before-wrapper" />
    <div class="swiper-wrapper">
      <slot />
    </div>
    <slot name="after-wrapper" />
  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { Navigation, Autoplay, Pagination, Swiper } from 'swiper/dist/js/swiper.esm.js'

Swiper.use([Pagination, Autoplay, Navigation])

export default {
  name: 'SwiperSlider',
  props: {
    options: { type: Object, default: null },
  },
  watch: {
    options() {
      this.recreateSwiper()
    },
  },
  mounted() {
    this.recreateSwiper()
  },

  updated() {
    this.swiper.update()
  },

  beforeDestroy() {
    if (this.swiper) this.swiper.destroy()
  },

  methods: {
    control(swiper) {
      this.controlled = swiper
      this.recreateSwiper()
    },

    recreateSwiper() {
      if (this.swiper) this.swiper.destroy()
      const o = Object.assign({}, {
        speed: 800,
        preloadImages: false,
        lazy: true,
        keyboard: {
          enabled: true,
          onlyInViewport: true,
        },
        a11y: true,
        navigation: {},
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        },
      },
      this.options,
      {
        controller: {
          control: this.controlled,
        },
      })

      this.swiper = new Swiper(this.$el, o)
      this.$emit('update:swiper', this.swiper)
    },
  },
}
</script>

<style>
.swiper-pagination-bullets {
  margin-top: 2rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  & .swiper-pagination-bullet {
    background: black;
    opacity: 0.1;
    width: 7px;
    height: 7px;
    margin: 0 5px;
    transition: all .2s ease;
  }

  & .swiper-pagination-bullet-active {
    opacity: 1;
    width: 11px;
    height: 11px;
    background: #1A2A56;
  }
}

.swiper-slide {
  box-sizing: border-box;
}

</style>
