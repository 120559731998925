<template>
  <article>
    <a :href="member.url" target="_self">
      <div class="aspect-ratio-13/17 relative">
        <template v-if="member.imageSrcSet">
          <div class="member-image lazyload"
             data-sizes="auto"
             :data-bgset="member.imageSrcSet"
             :style="{'background-image':'url('+ member.imageSrc +');'}">
          </div>
        </template>
        <template v-else>
          <div class="absolute inset-0 w-full bg-light-gray flex items-center justify-center">
            <IconPlaceholder class="h-32 md:h-32 w-auto" />
          </div>
        </template>
      </div>
      <h3 class="text-brand-blue text-center font-sofia-semi pt-4">{{ member.title }}</h3>
    </a>
  </article>
</template>

<script>
import IconPlaceholder from '@/assets/svg/icon-profile.svg?inline'
export default {
  components: {
    IconPlaceholder
  },
  props: {
    member: {
      type: Object,
      default () {
        return {}
      }
    },
  }
}
</script>

<style>
.member-image {
  @apply absolute inset-0;
  z-index: 2;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>