import BadgerAccordion from 'badger-accordion'
import ParsedURL from 'url-parse'
import closest from 'closest'
import { TweenLite, ScrollToPlugin } from 'gsap/all'

// eslint-disable-next-line
const keepMe = ScrollToPlugin

class Competence {
  constructor(element) {
    this._element = element
    this.activeSLBadgerItem = null

    this.init()
  }

  init() {
    this.accordeonMap = new Map()
    this.childAccordeons = new Map()

    this.initAccordion()
    this.initDeepLinking()
  }

  initAccordion() {
    this.badger = new BadgerAccordion(this._element)

    this._element.addEventListener('click', event => this.onToggleFirstLevelItem(event))

    const childAccordions = this._element.querySelectorAll('.js-competence-child-accordion')

    for (let i = 0; i < childAccordions.length; i++) {
      const childAccordion = childAccordions[i]
      const secondLevelAcc = new BadgerAccordion(childAccordion)
      this.childAccordeons.set(childAccordion.getAttribute('name'), { badger: secondLevelAcc })
      childAccordion.addEventListener('click', event => this.onToggleSecondLevelItem(event))
    }
  }

  initDeepLinking() {
    const flItems = this._element.querySelectorAll('.js-first-level')
    for (let i = 0; i < flItems.length; i++) {
      const item = flItems[i]
      let tmpArray = []
      const slItems = item.nextElementSibling.querySelectorAll('.js-second-level')
      for (let j = 0; j < slItems.length; j++) {
        const child = slItems[j]
        tmpArray.push(child.getAttribute('name'))
      }
      this.accordeonMap.set(item.getAttribute('name'), {index: i, childs: tmpArray})
    }
    setTimeout(() => this.checkURLForDeepLink(), 750)
  }

  checkURLForDeepLink() {
    const urlAnchor = this.getAccordionHash()
    if(!urlAnchor) {
      return
    }
    const query = this.getQueryParts()

    if (this.accordeonMap.has(query[0])) {
      const fLBadgerIndex = this.accordeonMap.get(query[0]).index
      const slBadgerItemIndex = this.accordeonMap.get(query[0]).childs.indexOf(query[1])

      this.disableDefaultPanelTransition()
      this.badger.closeAll()
      this.badger.open(fLBadgerIndex)

      if(slBadgerItemIndex !== -1) {
        const sLBadgerInstance = this.childAccordeons.get(query[1]).badger

        if(this.activeSLBadgerItem) {
          this.activeSLBadgerItem.closeAll()
        }
        sLBadgerInstance.open(0)
        this.activeSLBadgerItem = sLBadgerInstance
        this.goto(query[1])
      } else {
        this.goto(query[0])
      }
    }
  }

  getQueryParts() {
    const url = new ParsedURL(location.href)
    const tmp1 = url.hash.split('#', 2)
    const urlLevels = tmp1[1].split('/')

    return urlLevels
  }

  onToggleFirstLevelItem(event) {
    const header = closest(event.target, '.js-first-level', false)
    if(header) {
      this.pushFirstLevelQuery(header.getAttribute('name'))
    }
  }

  onToggleSecondLevelItem(event) {
    event.stopPropagation()
    const isContentLink = closest(event.target, '.js-sl-content', false)
    if(isContentLink) {
      setTimeout(() => this.checkURLForDeepLink(), 500)
    } else {
      const header = closest(event.target, '.js-second-level', false)
      this.pushSecondLevelQuery(header.getAttribute('name'))
    }
  }

  pushFirstLevelQuery(urlAnchor) {
    let url = new ParsedURL(location.href)
    url.set('hash', urlAnchor, false)
    window.history.pushState(null, null, url.toString())
  }

  pushSecondLevelQuery(urlAnchor) {
    let url = new ParsedURL(location.href)

    if (url.hash.indexOf('/') !== -1) {
      const tmp1 = url.hash.split('#', 2)
      const tmp2 = tmp1[1].split('/')
      url.set('hash', tmp2[0], false)
    }
    if (url.hash.indexOf('#') !== -1) {
      let tmp = url.hash.split('#', 2)
      urlAnchor = `${tmp[1]}/${urlAnchor}`
      url.set('hash', urlAnchor, false)
    }
    window.history.pushState(null, null, url.toString())
  }

   getAccordionHash() {
    let url = new ParsedURL(location.href)
    if (url.hash !== '') {
      return url.hash.substring(1)
    }
    return false
  }

  goto(urlAnchor) {
    const item = document.querySelector(`[name="${urlAnchor}"]`)
    const offset = item.getBoundingClientRect().top + (window.scrollY || window.pageYOffset)
    TweenLite.to(window, 1, { scrollTo:{ y: offset, autoKill: false }, onComplete: () => this.enableDefaultPanelTransition()})
  }

  disableDefaultPanelTransition() {
    this._element.classList.remove('transition')
  }

  enableDefaultPanelTransition() {
    this._element.classList.add('transition')
  }

}

export default Competence
