<template>
  <transition appear name="fade">
    <div class="bg-brand-blue text-white fixed bottom-0 left-0 md:flex justify-between w-full p-8 border-4 border-brand-blue z-10" v-if="isOpen">
      <div class="w-full max-w-6xl">
        <div class="u-p">
          <slot></slot>
        </div>
        <div class="flex mt-4">
          <div class="checkbox ml-4">
            <input type="checkbox" id="maps" @click="mapsPermission =! mapsPermission" :checked="mapsPermission">
            <label class="u-p" for="maps">Google Maps</label>
          </div>
        </div>
      </div>
      <div class="mo:mt-8 md:ml-8">
        <button class="button button--white" @click.prevent="closeLayer">{{ label }}</button>
      </div>
    </div>
  </transition>
</template>

<script>
import eventBus from '@/util/event-bus'
import { EVENT } from '@/util/enums'
import PermissionsHelper from '@/util/permissions'

export default {
  props: {
    label: {
      type: String,
      default () {
        return ''
      }
    },
  },
  data () {
    return {
      isOpen: false,
      analyticsPermission: 0,
      mapsPermission: 0,
    }
  },
  mounted() {
    this.updateCheckboxes()
    eventBus.$on(EVENT.PERMISSIONS_CHANGED, this.updateCheckboxes)

    if (this.mapsPermission == undefined || this.analyticsPermission == undefined){
      this.isOpen = true
    }
  },
  methods: {
    updateCheckboxes() {
      this.mapsPermission = PermissionsHelper.mapsPermission
    },
    closeLayer() {
      PermissionsHelper.mapsPermission = this.mapsPermission == undefined ? 0 : +this.mapsPermission

      eventBus.$emit(EVENT.PERMISSIONS_CHANGED)
      this.isOpen = false
    },
  }
}
</script>

<style>
.checkbox {
  user-select: none;
  cursor: pointer;

  & input {
    display: none;
  }

  & label {
    @apply flex flex-row;
    cursor: pointer;

    & a {
      border-bottom: 1px dotted currentColor;

      &:hover {
        border-bottom: 1px solid currentColor;
      }
    }
  }

  & label:before {
    @apply block w-6 h-6 bg-white border-2 border-black rounded mr-3 cursor-pointer flex-none;
    content: '';
  }

  & input:checked + label:before {
    @apply bg-brand-blue
  }

  &.checkbox input:checked + label:before {
    background-image: url(~@/assets/svg/icon-checkmark.svg);
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: 50% 50%;
  }

  & label span {
    @apply block relative;
    top: -1px;
  }
}
</style>
