<template>
  <div class="w-full md:flex flex-wrap mt-16">
    <div class="md:w-1/3 pb-16" v-for="(article, index) in paginatedNews" :key="index">
      <div class="flex mb-4">
        <template v-if="section === 'news'">
          <div v-for="(tag, index2) in article.tags" :key="index2">
            <div class="mr-2">
              <form :action="searchPageUrl">
                <input type="text" name="type" class="hidden" value="tag" />
                <input type="text" name="q" class="hidden" :value="tag.title" />
                <button class="tag" type="submit">{{ tag.title }}</button>
              </form>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="tag">{{ article.date }}, {{ article.location }}</div>
        </template>
      </div>
      <a class="news-header__title block font-sofia-semi text-p-fluid text-brand-blue rte pr-8"
        target="_self"
        :href="article.url"
        v-html="extractSoftHyphen(article.headline)">
      </a>
      <div v-if="section === 'news'" class="news-header__postdate text-gray text-xs pt-2"><span>{{ article.postDate }}</span></div>
    </div>
    <div class="w-full text-center sm:mt-8" v-show="news.length > 0 && !isLoading">
      <button @click="showMore()" class="button" v-show="morePagesAvailable">
        {{ labels.loadmore }}
      </button>
    </div>
    <div class="w-full text-center">
      <Spinner v-if="isLoading" />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Spinner from './Spinner'

const ARTICLES_PER_PAGE = 6

export default {
  props: {
    section: {
      type: String,
      default() {
        return ''
      }
    },
    apiBase: {
      type: String,
      default() {
        return ''
      }
    },
    labels: {
      type: Object,
      default() {
        return {}
      }
    },
    searchPageUrl: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      isLoading: true,
      page: 1,
      news: [],
    }
  },
  components: {
    Spinner,
  },
  computed : {
    paginatedNews() {
      return this.news.slice(0, this.page * ARTICLES_PER_PAGE)
    },
    morePagesAvailable() {
      return this.page < Math.ceil(this.news.length / ARTICLES_PER_PAGE)
    },
  },
  created() {
    const API_URL = `${this.apiBase}/api/${this.section}.json`
    axios.get(API_URL)
    .then(result => {
      if (result.data && result.data.data) {
        this.validateNewsList(result.data.data)
        this.isLoading = false
      } else {
        throw new Error('Invalid API response!')
      }
    })
    .catch(error => {
      console.log('error:', error)
    })
  },
  methods: {
    extractSoftHyphen(string) {
      return string.replace('(-)', '&shy;')
    },
    /*
     * remove latest "featured" article
     * @param list - Array
     */
    validateNewsList(list) {
      const featuredNews = list.filter(item => {
        return item.featured == true
      })
      const unwanted = featuredNews[0]
      this.news = list.filter(item => item != unwanted)
    },
    showMore() {
      this.page++
    },
  }
}
</script>

<style>

</style>
