<template>
  <div>
    <button class="button" @click.prevent="onClick">
      <slot name="button" />
    </button>
    <modal :name="instanceName" height="100%" :width="modalWidth" :scrollable="true">
      <div class="flex items-start justify-between pt-6 px-8">
        <h2 class="font-sofia-semi text-h2-fluid text-brand-blue rte">
          <slot name="headline" />
        </h2>
        <button class="close" @click="hide"><span /></button>
      </div>
      <iframe class="optin-form w-full h-full pb-12" :src="iFrameUrl"></iframe>
    </modal>
  </div>
</template>

<script>

const MODAL_WIDTH = 650

export default {
  props: {
    labels: {
      type: Object,
      default() {
        return {}
      }
    },
    to: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      modalWidth: MODAL_WIDTH
    }
  },
  created () {
    this.modalWidth = window.innerWidth < MODAL_WIDTH
      ? MODAL_WIDTH / 2
      : MODAL_WIDTH
  },
  computed: {
    instanceName() {
      return this.to === 'law' ? 'newsletter-optin-law' : 'newsletter-optin-privacy'
    },
    iFrameUrl() {
      return this.to === 'law' ? process.env.VUE_APP_N2G_LAW_LIST_IFRAME : process.env.VUE_APP_N2G_PRIVACY_LIST_IFRAME
    }
  },
  methods: {
    onClick() {
      this.show()
    },
    show () {
      this.$modal.show(this.instanceName)
    },
    hide () {

      this.$modal.hide(this.instanceName)
    }
  }
}
</script>

<style>
.close {
  @apply w-5 h-8 block relative cursor-pointer overflow-visible;

  &:before,
  &:after {
    @apply block w-full h-2px bg-brand-blue absolute;
    top: 50%;
    left: 0;
    transition: all .05s ease;
    content: '';
  }

  &:before {
    margin-top: -6px;
    @nest &{
      transform: translate(0, 6px) rotate(45deg);
    }
  }
  &:after {
    margin-top: 6px;
    @nest &{
      transform: translate(0, -6px) rotate(-45deg);
    }
  }
}
</style>
