import Cookies from 'js-cookie'

class PermissionsHelper {
  constructor() {
    throw new Error('Cannot construct singleton')
  }

  static set mapsPermission(value) {
    Cookies.set('roserGMEnabled' , value, { expires: 3650 })
  }

  static get mapsPermission() {
    return Cookies.get('roserGMEnabled')
  }

}

export default PermissionsHelper
